<script setup>
import { Link } from "@inertiajs/vue3";
import { computed } from "vue";

const props = defineProps({
    href: String,
    active: Boolean,
});

const classes = computed(() => {
    return props.active
        ? "p-ripple transition text-sm duration-150 ease-in-out bg-primary text-white"
        : "p-ripple transition text-sm duration-150 ease-in-out hover:bg-secondary";
});
</script>

<template>
    <Link
        :href="href"
        :class="[
            'p-3 sm:p-2 my-1 rounded-lg flex text-primary items-center gap-3 sm:gap-4 ',
            classes,
        ]"
    >
        <slot name="icon"></slot>
        <slot></slot>
    </Link>
</template>
