<script setup>
import NavLink from "@/Components/NavLink.vue";
import ResponsiveNavLink from "@/Components/ResponsiveNavLink.vue";

const { isMobile } = defineProps({
    isMobile: Boolean,
});

const navigationItems = [
    { name: "Scheduling", href: "#" },
    { name: "Admin", href: "#" },
    { name: "Reporting", href: "#" },
    { name: "Tasks", href: "#", badge: "10" },
    { name: "My Links", href: "#" },
    { name: "Patient Portal", href: "#" },
];
</script>

<template>
    <!-- Desktop Navigation -->
    <div
        v-if="!isMobile"
        class="hidden space-x-2 xl:space-x-8 sm:-my-px ms-6 xl:ms-10 xs:ms-1 lg:flex"
    >
        <NavLink v-for="item in navigationItems" :key="item.name" :href="item.href" :active="false">
            {{ item.name }}
            <span
                v-if="item.badge"
                class="bg-gray-200 dark:text-cerbo-primary rounded-full px-2 text-sm"
            >
                {{ item.badge }}
            </span>
        </NavLink>
    </div>

    <!-- Mobile Navigation -->
    <div v-else class="pt-2 pb-3 space-y-1">
        <ResponsiveNavLink
            v-for="item in navigationItems"
            :key="item.name"
            :href="item.href"
            :active="false"
        >
            {{ item.name }}
            <span v-if="item.badge" class="ml-2 bg-gray-200 rounded-full px-2 text-sm">
                {{ item.badge }}
            </span>
        </ResponsiveNavLink>
    </div>
</template>
